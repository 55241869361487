export const linesFragment = gql`
    fragment linesFragment on CartLine {
            id quantity discountAllocations {
                discountedAmount {
                    amount currencyCode
                }
            }
            attributes {
                key value
            }
            merchandise {
                ... on ProductVariant {
                    id title quantityAvailable priceV2 {
                        amount currencyCode
                    }
                    price {
                        amount currencyCode
                    }
                    product {
                        title id handle featuredImage {
                            url
                        }
                    }
                }
            }
            cost {
                totalAmount {
                    amount currencyCode
                }
                subtotalAmount {
                    amount currencyCode
                }
            }
        }
`;

export const costFragment = gql`
    fragment costFragment on CartCost {
        totalAmount {
            amount currencyCode
        }
        subtotalAmount {
            amount currencyCode
        }
        checkoutChargeAmount {
            amount currencyCode
        }
    }
`;


export const cartFragment = gql`
    ${linesFragment}
    ${costFragment}
    fragment cartFragment on Cart {
        id
        attributes {key value}
        cost {
            ...costFragment
        }
        deliveryGroups(first: 10){
           nodes {
               selectedDeliveryOption  {
                    code title description estimatedCost { amount currencyCode } deliveryMethodType  
               }
               deliveryOptions {
                    code title description estimatedCost { amount currencyCode }
               }
              cartLines(first: 10) {
                  nodes {
                      quantity
                      merchandise {
                            ... on ProductVariant {
                                id title
                            }
                      }
                  }
              }
           }
        }
        buyerIdentity {
            email countryCode phone
            customer {
                firstName lastName email phone
            }
            deliveryAddressPreferences {
                ... on MailingAddress {
                    name firstName lastName address1 address2 city province country 
                    zip countryCodeV2 province provinceCode
                }
            } 
            
        }
        discountCodes {
            code applicable 
        }
        discountAllocations {
           discountedAmount {
               amount currencyCode    
           } 
        }
        attributes {
            key value
        }
        lines(first: 10, after: $linesCursor) {
            edges {
                cursor
                node {
                    ...linesFragment
                }
            }
            pageInfo {
                hasNextPage endCursor
            }
        }
    }`
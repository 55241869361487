import {cartFragment, costFragment, linesFragment} from "~/apollo/fragments/cart";

export const createCart = gql`
    ${cartFragment}
    mutation createCart($input: CartInput!, $linesCursor: String) {
        cartCreate(input: $input) {
            cart {
               ...cartFragment 
            }
            userErrors {
                field
                message
            }
        }
    }
`


export const addLines = gql`
${cartFragment}
mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!, $linesCursor: String)  {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
        cart {
            ...cartFragment
        }
        userErrors {
            field message
        }
    }
}
`

export const removeLines = gql`
${cartFragment}
mutation cartLinesRemove($cartId: ID!, $lines: [ID!]!, $linesCursor: String)  {
    cartLinesRemove(cartId: $cartId, lineIds: $lines) {
        cart {
            ...cartFragment
        }
        userErrors {
            field message
        }
    }
}
`

export const updateLines = gql`
    ${linesFragment}
    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!, $linesCursor: String)  {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
            userErrors {
                field message
            }
            cart {
                lines(after: $linesCursor, first: 1) {
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            ...linesFragment
                        }
                    }
                }
            }
        }
    }
`

export const getCart = gql`
${cartFragment}   
query getCart($id: ID!, $linesCursor: String, $country: CountryCode) @inContext(country: $country) {
    cart(id: $id) {
        ...cartFragment
    }
}`

export const getCartItemsPage = gql`
${linesFragment}
query getCartPage($id: ID!, $linesCursor: String, $country: CountryCode) @inContext(country: $country) {
    cart(id: $id) {
        lines(first: 2, after: $linesCursor) {
            pageInfo {  
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                ...linesFragment
                }
            }
        }    
    }
}
`


export const discountCodesUpdate = gql`
    ${cartFragment} 
    mutation discountCodesUpdate($cartId: ID!, $discountCodes: [String!], $country: CountryCode, $linesCursor: String) @inContext(country: $country){
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
        cart {
           ...cartFragment
        }
        userErrors {
            field message
        }
    }
}`

export const cartBuyerIdentityUpdate = gql`
    ${cartFragment}
    mutation cartBuyerIdentityUpdate($cartId: ID!, $buyerIdentity: CartBuyerIdentityInput!, $country: CountryCode, $linesCursor: String) @inContext(country: $country) {
        cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
            cart {
                ...cartFragment
            }
            userErrors {
                field message
            }
        }
    }
`

export const cartAttributesUpdate = gql`
    ${cartFragment}
    mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!, $country: CountryCode, $linesCursor: String) @inContext(country: $country) {
        cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
            cart {
                ...cartFragment
            }
            userErrors {
                field message
            }
        }
    }
`

export const getCartCost = gql`
    ${costFragment}
    query getCartCost($id: ID!, $country: CountryCode) @inContext(country: $country) {
        cart(id: $id) {
            cost {
                ...costFragment
            }
        }
    }
`
import {MutationOptions, QueryOptions} from "@apollo/client/core/watchQueryOptions";
import {FetchResult} from "@apollo/client";
import {UseQueryReturn} from "@vue/apollo-composable";
import {UseQueryOptions} from "@vue/apollo-composable/dist/useQuery";

export const useQueryShopify = <T>(options: QueryOptions): UseQueryReturn<T, any> => {
    const { query } = options
    const variables = {
        ...options.variables,
        country: useCountry()
    }

    const queryOptions: UseQueryOptions<T> = {
        context: {
            headers: {
                "Shopify-Storefront-Buyer-IP": useClientIp()
            }
        },
    }
    return useQuery<T>(
        query,
        variables,
        queryOptions
    )
}

export const useMutationShopify = async <T>(options: MutationOptions): Promise<FetchResult<T>> => {
    const { client } = useApolloClient()
    const variables = {
        ...options.variables,
    }

    const result = await client.mutate<T>({
        mutation: options.mutation,
        variables,
        context: {
            headers: {
                "Shopify-Storefront-Buyer-IP": useClientIp()
            }
        }
    })
    return result
}
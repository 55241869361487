import type {
    Attribute,
    AttributeInput,
    Cart,
    CartBuyerIdentityInput,
    CartBuyerIdentityUpdatePayload,
    CartLineEdge,
} from "~/gql/schema";
import type {CartItem} from "~/models/cart";
import {cartAttributesUpdate} from "~/apollo/queries/carts";

export const useCartStore = defineStore(
    'cart',
    () => {
        const cart = ref<Cart | undefined>()

        const reset = () => {
            cart.value = undefined
        }
        const updateDiscountCode = async (codes: string[]) => {
            await useCart().updateDiscountCodes(cart.value?.id || "", codes)
            // await refreshCart()
        }
        const addToCart = async ({variantId, quantity = 1, sellerHandle, productHandle}: CartItem) => {
            const {createCart, addLine} = useCart()
            if (!cart.value) {
                const {data} = await createCart(variantId, quantity, sellerHandle, productHandle)
                cart.value = data?.cartCreate.cart
            } else {
                const {data} = await addLine(cart.value.id, variantId, quantity, sellerHandle, productHandle)
                cart.value = data?.cartLinesAdd.cart
            }
        }

        const removeFromCart = async (lineId: string) => {
            const {removeLine} = useCart()
            if (cart.value?.id) {
                const result = await removeLine(cart.value?.id, lineId)
                await getCart(cartId.value)
            }
        }
        const updateLineQuantity = async (lineId: string, quantity: number) => {
            if (quantity === 0) {
                await removeFromCart(lineId)
                return
            }
            const {updateLine} = useCart()
            if (cart.value?.id) {
                const index = cart.value.lines.edges.findIndex((e: CartLineEdge) => e.node.id === lineId)
                const edge = cart.value.lines.edges[index]
                const cursor = cart.value.lines.edges[index - 1]?.cursor || null
                if (!edge) return
                const {attributes, id} = edge?.node


                const result = await updateLine(
                    cart.value?.id,
                    cursor,
                    {
                        attributes: attributes.map((attr: Attribute) => ({key: attr.key, value: attr.value})),
                        quantity,
                        id
                    }
                )

                await getCart(cartId.value)
            }
        }


        const getCart = async (cartGid: string) => {
            cart.value = await useCart().getCart(cartGid)
        }

        const updateBuyerIdentity = async (buyerIdentity: CartBuyerIdentityInput): Promise<CartBuyerIdentityUpdatePayload> => {
            const {data} = await useCart().updateBuyerIdentity(
                cart.value?.id || "",
                buyerIdentity
            )

            const userErrors = data?.cartBuyerIdentityUpdate?.userErrors
            if (userErrors && userErrors.length > 0) {
                for (const error of userErrors) {
                    console.error("User error: ", error.message)
                }
            }

            await getCart(cartId.value)
            return data.cartBuyerIdentityUpdate
        }

        const updateCartAttributes = async (newAttributes: AttributeInput[]) => {
            const allAttributes = [...newAttributes, ...attributes.value]
            await useMutationShopify({
                mutation: cartAttributesUpdate,
                variables: {
                    cartId: cart.value?.id, attributes: allAttributes, country: useCountry()
                }
            })
            await getCart(cartId.value)
        }

        const validatePhone = async (email: string, phone: string) => {
            const {error: phoneCheckError} = await useApiFetch(
                `/public/phone_taken`,
                {
                    method: 'GET',
                    params: {
                        email: email,
                        phone: phone
                    }
                })
            if (phoneCheckError.value && phoneCheckError.value.statusCode === 409) {
                return false
            } else if (phoneCheckError.value) {
                console.error("Another error occurred while checking phone number, ", phoneCheckError.value)
            }
            return true
        }

        const attributes = computed(() => {
            if (!cart.value) return []
            return cart.value.attributes.map(attr => ({
                key: attr.key, value: attr.value
            })) || []
        })

        const totalDiscounts = computed(() => {
            if (!cart.value?.cost) return 0;
            return -1 * (parseFloat(cart.value.cost.subtotalAmount.amount) + shippingAmount.value - parseFloat(cart.value.cost.totalAmount.amount));
        });

        const discount = computed(() => {
            if (!cart.value || totalDiscounts.value === 0) return '';
            return useFormatMoney(totalDiscounts.value, cartCurrency.value);
        });

        const subTotal = computed(() => {
            if (!cart.value) return '';
            return useFormatMoney(cart.value.cost.subtotalAmount.amount, cart.value.cost.subtotalAmount.currencyCode);
        });

        const total = computed(() => {
            if (!cart.value) return '';
            return useFormatMoney(cart.value.cost.totalAmount.amount, cart.value.cost.totalAmount.currencyCode);
        });

        const deliveryAddress = computed(() => {
            if (!cart.value) return null;
            return cart.value.buyerIdentity?.deliveryAddressPreferences[0];
        });

        const cartCurrency = computed(() => {
            if (!cart.value) return "USD";
            return cart.value.cost.totalAmount.currencyCode;
        });

        const shippingAmount = computed(() => {
            if (!cart.value || !cart.value.deliveryGroups.nodes) {
                return 0;
            }
            return cart.value.deliveryGroups.nodes.reduce(
                (total, group) => {
                    if (!group.selectedDeliveryOption) return total;
                    return total + parseFloat(group.selectedDeliveryOption.estimatedCost.amount);
                },
                0
            );
        });

        const shipping = computed(() => {
            if (!cart.value || cart.value.deliveryGroups.nodes.length == 0) {
                return "To be determined";
            }
            return useFormatMoney(shippingAmount.value, cartCurrency.value);
        });

        const province = computed(() => {
            if (!cart.value) return "";
            return cart.value.attributes.find(a => a.key === "province")?.value || "";
        });

        const newsletter = computed(() => {
            if (!cart.value) return false;
            return cart.value.attributes.find(a => a.key === "newsletter")?.value === "true";
        });

        const quantity = computed(() => {
            if (cart.value?.lines.edges.length == 0) return 0;
            return cart.value?.lines.edges.reduce(
                (total, edge) => total + edge.node.quantity, 0)
        });

        const cartId = computed(() => {
            if (!cart?.value) return "";
            return cart.value.id;
        });

        const lines = computed(() => {
            if (!cart.value) return [];
            return cart.value.lines.edges.map(edge => edge.node);
        });

        const sellerHandle = computed(() => {
            if (!cart.value || !lines.value[0]) return "";
            return lines.value[0].attributes.find(a => a.key == 'sellerHandle')?.value || "";
        });

        const currency = computed(() => {
            if (!cart.value) return "USD";
            return cart.value.cost.totalAmount.currencyCode;
        })

        const totalAmount = computed(() => {
            if (!cart.value) return 0;
            return parseFloat(cart.value.cost.totalAmount.amount);
        })

        return {
            quantity, cart,
            getCart, reset, addToCart, removeFromCart, updateDiscountCode, updateLineQuantity,
            validatePhone, updateBuyerIdentity, updateCartAttributes,
            cartId, lines, total, sellerHandle, currency, totalAmount, newsletter, deliveryAddress, province,
            subTotal, shipping, discount, cartCurrency
        }

    },
    {
        persist: {
            storage: persistedState.localStorage,
        }
    }
)
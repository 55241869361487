import {
    addLines,
    cartBuyerIdentityUpdate,
    createCart,
    discountCodesUpdate,
    getCart, getCartCost, getCartItemsPage,
    removeLines, updateLines
} from "~/apollo/queries/carts";
import {AttributeInput, Cart, CartBuyerIdentityInput} from "~/gql/schema";
import {UseQueryReturn} from "@vue/apollo-composable";


export const useCart = () => ({
    createCart: (variantId: string, quantity: number = 1,  sellerHandle: string, productHandle: string)=> {
        return useMutationShopify<{createCart: { cart: Cart }}>(
            {
                mutation: createCart,
                variables: {
                    input: {
                        lines: [
                            {
                                quantity,
                                merchandiseId: variantId,
                                attributes: [
                                    {
                                        key: 'sellerHandle',
                                        value: sellerHandle
                                    },
                                    {
                                        key: 'productHandle',
                                        value: productHandle
                                    }
                                ]
                            }
                        ],
                        attributes: sellerHandle != null ? [{ key: 'seller', value: sellerHandle }] : [],
                        buyerIdentity: { countryCode: useCountry() }
                    }
                }
            }
        )
    },
    async getCart(cartId: string): Promise<Cart|undefined> {
        const { result, fetchMore, loading } = useQueryShopify<{cart: Cart}>(
            {
                query: getCart,
                variables: {
                    id: cartId,
                    linesCursor: undefined
                }
            }
        )
        while (loading.value) {
            await new Promise(resolve => setTimeout(resolve, 100))
        }

        if (!result.value?.cart) {
            return undefined
        }

        while(result.value?.cart.lines.pageInfo.hasNextPage) {
            const cursor = result.value?.cart.lines.pageInfo.endCursor
            await fetchMore({
                variables: {linesCursor: cursor, id: cartId},
                query: getCartItemsPage,
                updateQuery: (previousQueryResult, options) => {
                    const {fetchMoreResult} = options
                    if (!fetchMoreResult) return previousQueryResult
                    const newEdges = fetchMoreResult.cart.lines.edges
                    const pageInfo = fetchMoreResult.cart.lines.pageInfo
                    const combinedCart = {
                        cart: {
                            ...previousQueryResult.cart,
                            lines: {
                                ...previousQueryResult.cart.lines,
                                edges: [...previousQueryResult.cart.lines.edges, ...newEdges],
                                pageInfo
                            }
                        }
                    }
                    return combinedCart
                },
            })
        }
        return result.value?.cart
    },
    updateDiscountCodes: (cartId: string, codes: string[]) => {
        return useMutationShopify(
            {
                mutation: discountCodesUpdate,
                variables: {
                    cartId,
                    discountCodes: codes,
                    country: useCountry()
                }
            }
        )
    },
    updateBuyerIdentity: (cartId: string, buyerIdentity: CartBuyerIdentityInput) => {
        return useMutationShopify(
            {
                mutation: cartBuyerIdentityUpdate,
                variables: {
                    cartId, buyerIdentity, country: useCountry()
                }
            }
        )
    },
    addLine: (cartId: string, variantId: string, quantity: number = 1, sellerHandle: string, productHandle: string)=> {
        const variables = {
            cartId,
            lines: [
                {
                    merchandiseId: variantId,
                    quantity: quantity,
                    attributes: [
                        {
                            key: 'sellerHandle',
                            value: sellerHandle
                        },
                        {
                            key: 'productHandle',
                            value: productHandle
                        }
                    ]


                }

            ]
        }
        const { mutate } = useMutation(
            addLines,
        )
        return mutate(variables)
    },
    removeLine: (cartId: string, lineId: string) => {
        const variables = {
            cartId,
            lines: [lineId]
        }
        const { mutate } = useMutation(
            removeLines
        )
        return mutate(variables)
    },
    updateLine: (cartId: string, cursor: string, lineInput: {quantity: number, id: string, attributes: AttributeInput[]}) => {
        const variables = {
            cartId,
            linesCursor: cursor,
            lines: [lineInput]
        }
        const { mutate } = useMutation(
            updateLines,
        )
        return mutate(variables)
    },
    getCost: (cartId: string) => {
        const { result, loading } = useQueryShopify<{cart: Cart}>(
            {
                query: getCartCost,
                variables: {
                    id: cartId,
                }
            }
        )
        return result.value?.cart.cost
    }
})